import { Box, Grid, Text } from "@chakra-ui/react";

export default function Footer(){
    return (
        <Box  px={{
                base: 0,
                lg: 0,
                }} 
                mx="auto"
                color="white"
                background="#000"
                marginTop={40}
                minHeight={300}
                >
            <Box 
              mx="auto"
              maxWidth={1024}
            >
              <Grid
              mt={30}
              px={{
                base: 0,
                lg: 0,
              }}
              pt={50}
              columnGap={500}
              rowGap={8}
              templateColumns={{
                sm: "1fr",
                md: "1fr 1fr",
                lg: "repeat(1, 1fr)",
                xl: "repeat(2, 1fr)",
                "2xl": "repeat(2, 1fr)",
              }}
              >         
              <Box  position="relative" overflow={"hidden"} mb={0.1} >
              
                <Text  style={{color: "white", fontSize: "12px"}}  align={"left"} >ⓒ 2022 Fand Korea Co., Ltd.</Text>
                <Text pt={10} style={{color: "white", fontSize: "13px"}}  align={"left"} >팬드코리아</Text>
                <Text pt={2} style={{color: "white", fontSize: "12px"}}  align={"left"} >사업자 등록번호 413-86-00194 | 대표:김동민</Text>
                <Text pt={2} style={{color: "white", fontSize: "12px"}}  align={"left"} >서울 영등포구 영등포로 150 10층 비1001, 1002호(당산동1가, 생각공장 당산)</Text>
              </Box>
          
              <Box  position="relative" overflow={"hidden"} mb={0.1}>
                
                <Grid
                
                rowGap={8}
                templateColumns={{
                    sm: "1fr",
                    md: "1fr 1fr",
                    lg: "repeat(1, 1fr)",
                    xl: "repeat(2, 1fr)",
                    "2xl": "repeat(2, 1fr)",
                }}
                >      
              <Box>
                <Text pt={2} style={{color: "white", fontSize: "12px"}}  align={"left"} >회사</Text>
                  <Text pt={5} style={{color: "white", fontSize: "12px"}}  align={"left"} >팬드소식</Text>
                  <Text pt={5} style={{color: "white", fontSize: "12px"}}  align={"left"} >이용약관</Text>
                <Text pt={5} style={{color: "white", fontSize: "12px"}}  align={"left"} >개인정보처리방침</Text>
                </Box>
                <Box>
                <Text pt={2} style={{color: "white", fontSize: "12px"}}  align={"left"} >고객지원</Text>
                <Text pt={5} style={{color: "white", fontSize: "12px"}}  align={"left"} >공지사항</Text>
                </Box>
                </Grid>
              </Box>
        
              </Grid>
                  
            </Box>
                    
        </Box>
    )
} 
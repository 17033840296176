import { Box, Grid, HStack, Image, Skeleton, SkeletonText, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getItem, getItems } from "../api";
import Item from "./Item";
import ItemSkeleton from "./ItemSkeleton"
import {IItem} from "../types"




export default function ItemList() {
 const {isLoading,data} = useQuery<IItem[]>(["items"],getItems)
  
  return (

        <Grid
        mt={10}
         px={{
          base: 0,
          lg: 0,
        }}
        columnGap={4}
        rowGap={8}
        templateColumns={{
          sm: "1fr",
          md: "1fr 1fr",
          lg: "repeat(1, 1fr)",
          xl: "repeat(1, 1fr)",
          "2xl": "repeat(1, 1fr)",
        }}
      >

        {isLoading ? (
          <>
            <ItemSkeleton/>
            <ItemSkeleton/>
            <ItemSkeleton/>
            <ItemSkeleton/>
            <ItemSkeleton/>
          </>
          
        ) : null}
        
        {data?.map((item)=>(
          <Item
            //imageUrl = {item.y_thum[0].file}
            key ={item.pk}
            pk = {item.pk}
            name={item.name}
            imageUrl = {item.y_thum}
            y_sub_cnt = {item.y_sub_cnt}
            price = {item.price}
            quantity={item.quantity}
            itemhistorydata ={item.itemhistorydata[0].h_eps}
            count_per= {item.count_per}
          />
        ))}

     
    
  
      </Grid>
    )
  }
import {useForm} from "react-hook-form"
import {
    Box,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    useToast,
    VStack,
  } from "@chakra-ui/react";
import { ReactJSXElementAttributesProperty } from "@emotion/react/types/jsx-namespace";
import { useState } from "react";
  import { FaUserNinja, FaLock, FaUserCheck } from "react-icons/fa";
  import SocialLogin from "./SocialLogin";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IUsernameLoginError, IUsernameLoginSuccess, IUsernameLoginVariables, usernameLogin } from "../api";
  
  interface LoginModalProps {
    isOpen: boolean;
    onClose: () => void;
  }
  
  interface Ifrom{
    username:string;
    password:string;
  }


  export default function LoginModal({ isOpen, onClose }: LoginModalProps) {
    const {register,handleSubmit,formState:{errors},setValue,reset} = useForm<Ifrom>();
    const toast = useToast()
    const queryClient = useQueryClient();
    const mutation = useMutation<IUsernameLoginSuccess,IUsernameLoginError,IUsernameLoginVariables>(usernameLogin,{
      onMutate:() =>{
        console.log("m start");
      },
      onSuccess:(data)=>{
       
        toast({
          title:"안녕하세요",
          status:"success",
        })
        onClose();
        reset();
        queryClient.refetchQueries(['me'])
      },
      onError:()=>{
        toast({
          title:"아이디 및 비밀번호를 확인해주세요",
          status:"error",
        })
        console.log("m errer")
      },
    })
    const onSubmit =({username,password}:Ifrom) =>{
      mutation.mutate({username,password});
    }
    //console.log(mutation);
    //console.log(errors);


    // const [username,onChangeUsername] = useState("");
    // const [password,onChagePassword] = useState("");
    // const [email,setEmail] = useState("");
    // const [emailError,setEmailError] = useState("");
    // const onChange = (event:React.SyntheticEvent<HTMLInputElement>) =>{
    //   const {name,value} = event.currentTarget;
    //   if(name === "username"){
    //       onChangeUsername(value)
    //   }else if(name === "password"){
    //     onChagePassword(value)
    //   }
    // }
    // const onSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    //   event.preventDefault();
    //   if (!email.includes("@")) {
    //     setEmailError("please write a valid email");
    //   }
    //   console.log(username, password);
    // };

    return (
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>로그인</ModalHeader>
          <ModalCloseButton />
          <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}  >
            <VStack>
              <InputGroup size={"md"}>
                <InputLeftElement
                  children={
                    <Box color="gray.500">
                      <FaUserCheck />
                    </Box>
                  }
                />
                <Input 
                  isInvalid={Boolean(errors.username?.message)}
                  {...register("username",{
                  required:"유저네임을 입력해주세요",
                })}
                  variant={"filled"} placeholder="Username" />
             
              </InputGroup>
              <InputGroup>
                <InputLeftElement
                  children={
                    <Box color="gray.500">
                      <FaLock />
                    </Box>
                  }
                />
                <Input 
                isInvalid={Boolean(errors.password?.message)}
                {...register("password",{
                  required:"패스워드를 입력해주세요.",
                })}
                type="password"
               
                variant={"filled"} 
                placeholder="Password" />
                
              </InputGroup>
            </VStack>
            {mutation.isError ? <Text color={"red.500"} textAlign={"center"} fontSize="sm">아이디 또는 비밀번호를 확인해주세요</Text>: null}
            <Button isLoading={mutation.isLoading} type="submit" mt={4} colorScheme={"red"} w="100%">
              로그인
            </Button>
            <SocialLogin />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
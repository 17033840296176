import { Heading, Spinner, Text, useToast, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { githubLogIn } from "../api";

export default function GithubConfirm() {
  const { search } = useLocation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const confirmLogin = async () => {
    const params = new URLSearchParams(search);
    const code = params.get("code");
    const result_status:Number = 200
    if (code) {
      const status = await githubLogIn(code);
      console.log(status)
      toast({
              status: "success",
              title: "Welcome!",
              position: "bottom-right",
              description: "Happy to have you back!",
            });
    queryClient.refetchQueries(["me"]);
    navigate("/");  
    

    //   if (status === 200) {
    //     toast({
    //       status: "success",
    //       title: "Welcome!",
    //       position: "bottom-right",
    //       description: "Happy to have you back!",
    //     });
    //     queryClient.refetchQueries(["me"]);
    //     navigate("/");
    //   }
    }
  };
  useEffect(() => {
    confirmLogin();
  }, []);
  return (
    <VStack justifyContent={"center"} mt={40}>
      <Heading>Processing log in...</Heading>
      <Text>잠시만 기달려주세요...</Text>
      <Spinner size="lg" />
    </VStack>
  );
}
import { Box, Skeleton } from "@chakra-ui/react";



export default function ItemSkeleton(){
    return (
    <Box>
        <Skeleton rounded="2xl" height={280} width="100%" mb={3}/>
        {/* <SkeletonText noOfLines={3}/> */}
    </Box>
    )

} 


import { Box, Grid, Image, Progress, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {ItemProps} from "../types"
import "./Item.css"


export default function Item({imageUrl,name,y_sub_cnt,price,quantity,pk,itemhistorydata,count_per}:ItemProps) {
    return (
    
    
    <Link to={`${pk}`}>
        <Box position="relative" borderWidth={1}  overflow={"hidden"} mb={0.1} rounded="2xl">
        <Grid gap={2} templateColumns={"3fr 6fr"}>
            <Image
            //w={"100%"}
            maxW={"100%"}
            minH="250"
            height={"100%"}
            src={imageUrl}
            />
            
            <Box px={10} mt={10}>                 
                <Grid gap={2} templateColumns={"6fr 3fr"}>
                  <Box>
                  <Text display={"block"} as="b" noOfLines={1} fontSize="md">
                    {name}
                </Text>
                    <Text  mt={10} display={"block"} as="b" noOfLines={1} fontSize="md">
                    구독자 수 {[y_sub_cnt].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}명
                    </Text>
                    <Text>투자기간 1년</Text>
                      
                    <Text>주당금액 : {[price].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</Text>
                        <Text>직전 배당금 : {itemhistorydata} </Text>              
                  </Box>
                 
                    <Box mt={50}>
                        
                        <Progress mt={10} value={count_per} size='xs'  colorScheme='green' />
                        <Text>펀딩 진행률 : {count_per}%</Text>
                    </Box>
                </Grid>
            </Box>
        </Grid>
        </Box>      
    </Link>
    )
  }


import { Box, Grid, HStack, Image, Text, VStack } from "@chakra-ui/react"
import { ClassNames } from "@emotion/react"
import React, { Component } from "react"
import "./Home.css"
import { Link } from "react-router-dom";
import MainSlide from "../components/MainSlide";
import CreatorSlide from "../components/CreatorSlide";
import NoticeLitmit from "../components/NoticeLitmit"
import ScrollToTop from "../lib/ScrollToTop";
import CreatorSlideEx from "../components/CreatorSlideEx";


export default function Home() {
    return (
    <>
      <Box marginTop={20} width="100%">
        <MainSlide/>
      </Box>

      <Box  
        marginTop={20}
        mx="auto"
        maxWidth={1024}
      
        >
        <Box   >
          <Text fontSize="2xl" mb={30} as={"b"}>팬드 추천 채널</Text>
          <CreatorSlide className="mt" />
        </Box>
        
        <Box  marginTop={10}>
          <Text fontSize="2xl" mb={30} as={"b"}>팬드 오픈 예정 채널</Text>
          <CreatorSlideEx className="mt" />
        </Box>
             
       
       
        <Box marginTop={20}>
          <Image mt={10} src="img/banner_1.png"></Image>
        </Box>
        
        <Box marginTop={20}>
          <Image mt={10} src="img/banner_2.png"></Image>
        </Box>

        <Box  marginTop={20}>
          <Text fontSize="2xl" mb={30} as={"b"}>팬드 매거진</Text>
          <Grid
          mt={10}
          px={{
            base: 0,
            lg: 0,
          }}
          columnGap={10}
          rowGap={8}
          templateColumns={{
            sm: "1fr",
            md: "1fr 1fr",
            lg: "repeat(1, 1fr)",
            xl: "repeat(1, 1fr)",
            "2xl": "repeat(4, 1fr)",
          }}
          >         
          <Box p={1}  position="relative" overflow={"hidden"} mb={0.1} rounded="lg">
             <Box borderWidth={1} >
               <Image mt={10} src="https://kasa.co.kr/_next/image?url=%2Fimages%2Fhome_info_image_01.png&w=640&q=100"></Image>
              </Box>
            <Text mt={5} align={"center"} >유튜브 애드센스 수익<br></br> 구독자가 중요한가</Text>
          </Box>
          <Box p={1}  position="relative" overflow={"hidden"} mb={0.1} rounded="lg">
             <Box borderWidth={1} >
               <Image mt={10} src="https://kasa.co.kr/_next/image?url=%2Fimages%2Fhome_info_image_01.png&w=640&q=100"></Image>
              </Box>
            <Text mt={5} align={"center"} >유튜브 애드센스 수익<br></br> 구독자가 중요한가</Text>
          </Box>
          <Box p={1}  position="relative" overflow={"hidden"} mb={0.1} rounded="lg">
             <Box borderWidth={1} >
               <Image mt={10} src="https://kasa.co.kr/_next/image?url=%2Fimages%2Fhome_info_image_01.png&w=640&q=100"></Image>
              </Box>
            <Text mt={5} align={"center"} >유튜브 애드센스 수익<br></br> 구독자가 중요한가</Text>
          </Box>
          <Box p={1}  position="relative" overflow={"hidden"} mb={0.1} rounded="lg">
             <Box borderWidth={1} >
               <Image mt={10} src="https://kasa.co.kr/_next/image?url=%2Fimages%2Fhome_info_image_01.png&w=640&q=100"></Image>
              </Box>
            <Text mt={5} align={"center"} >유튜브 애드센스 수익<br></br> 구독자가 중요한가</Text>
          </Box>

          </Grid>
        </Box>       

     
        <Box marginTop={20}>
          <Image mt={10} src="img/banner_3.png"></Image>
        </Box>

        <Box  marginTop={20}>
          <Grid
          mt={10}
          px={{
            base: 0,
            lg: 0,
          }}
          columnGap={10}
          rowGap={8}
          templateColumns={{
            sm: "1fr",
            md: "1fr 1fr",
            lg: "repeat(1, 1fr)",
            xl: "repeat(1, 1fr)",
            "2xl": "repeat(2, 1fr)",
          }}
          >         
          <Box p={1}   backgroundColor={"#58d0b6"} position="relative" borderWidth={1}  overflow={"hidden"} mb={0.1} rounded="lg">
            <a href="http://beproud.co.kr/" target={"_blank"}>
              <Text className="baner_text" color={"#fff"} align={"center"}>유튜브 마케팅 문의</Text>
            </a>
          </Box>
          <Box p={1}   backgroundColor={"#58d0b6"} position="relative" borderWidth={1}  overflow={"hidden"} mb={0.1} rounded="lg">
            <Text  className="baner_text" color={"#fff"} align={"center"}>제휴 제안 문의</Text>
            
          </Box>
  
          </Grid>
        </Box>       


        <Box mt={20}>
          <Text fontSize="2xl"  as={"b"}>공지사항 </Text><Link  to={`/support/notices`}><span className="addNotice">더보기</span></Link>
          <NoticeLitmit/>
        </Box>
         
      </Box>
     
   </>
    )
    
  }
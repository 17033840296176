import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getNotice } from "../api";
import moment from "moment";
import { useParams } from "react-router-dom";
import ScrollToTop from "../lib/ScrollToTop";
import Parse from "html-react-parser";

export default function Notice() {
  const { noticePk } = useParams();
  const { isLoading, data } = useQuery(["", noticePk], getNotice);
  const test =
    "안녕하세요. 카사입니다.\n아래와 같이 부티크호텔 르릿 2기 배당금 지급 관련하여 안내해 드립니다.";

  console.log(data);
  return (
    <Box
      px={{
        base: 0,
        lg: 0,
      }}
      mx="auto"
      maxWidth={1024}
      marginTop={40}
      minHeight={500}
      className=""
    >
      <Text fontSize="2xl" as="b" color={"#282828"}>
        공지사항
      </Text>

      <Text fontSize="lxl" mt={10}>
        {data?.subject}
      </Text>

      <Box mt={5} borderWidth={1} rounded="lg">
        <Text p={10} style={{ whiteSpace: "pre-wrap" }}>
          {isLoading ? <></> : Parse(data?.context)}
        </Text>
      </Box>

      <p className="">
        {test.split("\n").map((line) => {
          <span>
            1{line}
            <br />
          </span>;
        })}
      </p>
      <ScrollToTop />
    </Box>
  );
}

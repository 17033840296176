import { Box, Text } from "@chakra-ui/react"
import React from "react"
import ItemList from "../components/ItemList"
import ScrollToTop from "../lib/ScrollToTop";


export default function Market() {
    return (
      // <Box>dd</Box>
      <Box   px={{
          base: 0,
          lg: 0,
        }} 
        mx="auto"
        maxWidth={1024}
        
        marginTop={40}
        minHeight={1200}
        className="">
          <Text fontSize="2xl" as="b" color={"#282828"}>펀딩 가능한 채널</Text>
          <ItemList/>
          <ScrollToTop /> 
      </Box>
      
    )
  }
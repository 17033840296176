import React from 'react';
import { ChakraProvider } from "@chakra-ui/react";
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ScrollToTop from './lib/ScrollToTop';

const client = new QueryClient();

const root = ReactDOM.createRoot(
  
  document.getElementById('root') as HTMLElement
);
root.render(
    
    <QueryClientProvider client={client}>
      
      <ChakraProvider>
    
          <RouterProvider   router={router}/>
          
      </ChakraProvider>
    </QueryClientProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))

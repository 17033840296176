import { FaAirbnb, FaMoon } from "react-icons/fa";
import {
  Box,
  Button,
  HStack,
  IconButton,
  useDisclosure,
  Grid,
  GridItem,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  Text,
  ToastId,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import LoginModal from "./LoginModal";
import SignUpModal from "./SignUpModal";
import { useEffect, useState,useRef  } from "react";
import "./Header.css"
import useUser from "../lib/useUser";
import { logOut } from "../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

//import SignUpModal from "./SignUpModal";


export default function Header() {
  const { userLoading, isLoggedIn, user } = useUser();
 
  const {
    isOpen: isLoginOpen,
    onClose: onLoginClose,
    onOpen: onLoginOpen,
  } = useDisclosure();
  const {
    isOpen: isSignUpOpen,
    onClose: onSignUpClose,
    onOpen: onSignUpOpen,
  } = useDisclosure();
 

  const location = useLocation(); 
  const [url, setUrl] = useState('');

  
  useEffect(() => {
   const path = location.pathname;
   setUrl(path);
  }, [location]);

  const toast = useToast();
  const queryClient = useQueryClient();
  const toastId = useRef<ToastId>();
  const mutation = useMutation(logOut,{
    onMutate:()=>{
       toastId.current = toast({
        title: "Login out...",
        description: "Sad to see you go...",
        status: "loading",
        position: "bottom-right",
      });
    },
    onSuccess:()=>{
      if(toastId.current){
      queryClient.refetchQueries(["me"])
      toast.update(toastId.current, {
        status: "success",
        title: "Done!",
        description: "로그아웃",
      });
    }
    },
    onError:()=>{

    }
  })

  const onLogOut =async () => {
    mutation.mutate();

  }

  return (
    <Box
    top={0}
    position={"fixed"}
    left={0}
    bg="white"
    height="80px"
    width={"100%"}
    zIndex={999}
    borderBottomWidth={1}
    >
      <HStack
        justifyContent={"space-between"}
        py={4}
        px={1}
        mx="auto"
        maxWidth={1024}
       
        
        
      >
        {/* <Box color="red.500">
          <Link to={"/"}>
            <FaAirbnb size={"48"} />
          </Link>
        </Box> */}
        <Grid templateColumns='repeat(4, 1fr)' gap={7} width="" >
            <Link  to={"/"}>
              <Text fontSize="3xl" as="b"  color={"#58d0b6"} >FAND</Text>
            </Link>
              <GridItem   ml={4} mt={3} w='100%' h='5'  ><Link to="/" className={"underline" + (url === "/" ? " active" : "")}>ABOUT</Link></GridItem>
              <GridItem  ml={0} mt={3}  w='100%' h='5'  ><Link to="/market" className={"underline" + (url === "/market" || url == "market/:id" ? " active" : "")}>MARKET</Link></GridItem>
              <GridItem  ml={0} mt={3}  w='100%' h='5'  ><Link to="/contact" className={"underline" + (url === "/contact" || url == "contact" ? " active" : "")}>CONTACT</Link></GridItem>
        </Grid>     
        <HStack spacing={2}>
          {/* <IconButton
            variant={"ghost"}
            aria-label="Toggle dark mode"
            icon={<FaMoon />}
          /> */}
         {!userLoading ? (
          !isLoggedIn ? (
            <>
              <Button fontSize={"18px"} color={"#989898"}  background={"#fff"}  onClick={onSignUpOpen} >
                JOIN
              </Button>
              <Button fontSize={"18px"}   color={"#989898"} background={"#fff"} onClick={onLoginOpen}>LOGIN</Button>

              
            </>
          ) : (
            <Menu>
              <MenuButton>
                <Avatar name={user.name} src={user.avatar} size={"md"} />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={onLogOut}>로그아웃</MenuItem>
                <MenuItem ><Link to="./mypage">마이페이지</Link></MenuItem>
              </MenuList>
            </Menu>
          )
        ) : null}
        </HStack>
        <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
        <SignUpModal isOpen={isSignUpOpen} onClose={onSignUpClose} />
        
      </HStack>
    </Box>
  );
}
import {
  Box,
  Grid,
  TabIndicator,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { React, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import useProtectedPage from "../components/ProtectedPage";
import useUser from "../lib/useUser";

export default function MyPage() {
  useProtectedPage();
  const { userLoading, isLoggedIn, user } = useUser();
  const [history, setHistory] = useState();

  useEffect(() => {
    if (user) {
      const fund_history = user["history"];
      setHistory(fund_history);
    }
  }, [isLoggedIn, userLoading]);

  return (
    <Box
      px={{
        base: 0,
        lg: 0,
      }}
      mx="auto"
      maxWidth={1024}
      marginTop={40}
      minHeight={1200}
      className=""
    >
      <Text fontSize="3xl" as="b" color={"#282828"}>
        마이페이지
      </Text>
      <Grid
        mt={10}
        px={{
          base: 0,
          lg: 0,
        }}
        columnGap={20}
        rowGap={8}
        templateColumns={{
          sm: "1fr",
          md: "1fr 1fr",
          lg: "repeat(2, 2fr)",
          xl: "repeat(2, 2fr)",
          "2xl": "repeat(2, 2fr)",
        }}
      >
        <Box overflow={"hidden"} mb={0.1}>
          <Text as="b">내 정보</Text>
          <Box
            mt={5}
            padding={5}
            borderWidth={1}
            overflow={"hidden"}
            mb={0.1}
            rounded="md"
          >
            <Grid templateColumns="repeat(2, 2fr)">
              <Box w="100%">
                <Text>이름</Text>
                <Text mt={3}>휴대폰 번호</Text>
                <Text mt={3}>이메일</Text>
                <Text mt={3}>주소</Text>
              </Box>
              <Box w="100%">
                <Text>{user?.name}</Text>
                <Text mt={3}> {user?.phone_number ? " " : "입력필요"} </Text>
                <Text mt={3}>{user?.email}</Text>
                <Text mt={3}>{user?.juso}</Text>
              </Box>
            </Grid>
          </Box>
        </Box>

        <Box overflow={"hidden"} mb={0.1}>
          <Text as="b">연결계좌</Text>
          <Box
            mt={5}
            padding={5}
            borderWidth={1}
            overflow={"hidden"}
            mb={0.1}
            rounded="md"
          >
            <Text>보유캐쉬 : {user?.account[0].balance}</Text>
            <Text mt={5}>총 평가금액 </Text>
          </Box>
        </Box>
      </Grid>
      <Box
        mt={5}
        padding={5}
        borderWidth={1}
        overflow={"hidden"}
        mb={0.1}
        rounded="md"
      >
        <Text>보유목록</Text>
        <TableContainer mt={5}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>크리에이터</Th>
                <Th>수량</Th>
                <Th>가격</Th>
                <Th>주당 예상 배당금</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoggedIn
                ? history?.map((a, i) => (
                    <Tr key={i}>
                      <Td>{a.item.name}</Td>
                      <Td>{a.quantity}</Td>
                      <Td>{a.price}</Td>
                      <Td>800원</Td>
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

import { Heading, VStack,Text, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function NotFound() {
    return (
        <VStack bg="gray.100" justify={"center"} minH ="100vh">
            <Heading>Page not found.</Heading>
            <Text>이페이지는 없는 페이지입니다.</Text>
            <Link to="/">
            <Button colorScheme={"twitter"}  variant={"link"}>GO HOME &rarr;</Button>
            </Link>
          
        </VStack>
    )
}
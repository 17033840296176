import { Box, Text } from "@chakra-ui/react";
import { Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getNotices } from "../api";
import moment from "moment";
import ScrollToTop from "../lib/ScrollToTop";

export default function Notices() {
  const { isLoading, data } = useQuery([``, "notice"], getNotices);

  return (
    <Box
      px={{
        base: 0,
        lg: 0,
      }}
      mx="auto"
      maxWidth={1024}
      marginTop={40}
      minHeight={1200}
      className=""
    >
      <Text fontSize="2xl" as="b" color={"#282828"}>
        공지사항
      </Text>
      <TableContainer mt={5}>
        <Table variant="simple">
          <Tbody>
            {data?.map((item) => (
              //created_at = moment(item.created_at).format('YYYY-MM-DD');

              <Tr>
                <Td padding={5}>{item.subject}</Td>

                <Td w={10}>{moment(item.created_at).format("YYYY-MM-DD")}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <ScrollToTop />
    </Box>
  );
}


import { useFocusEffect } from "@chakra-ui/react";
import React, { useEffect } from "react"
import useUser from "../lib/useUser";
import { Link, Navigate, useNavigate } from "react-router-dom";

export default function useProtectedPage(){
    const {user,isLoggedIn,userLoading} = useUser();
    const navigate = useNavigate();
    useEffect(()=>{
        if(!userLoading){
            if(!isLoggedIn){
               
                navigate("/")
            }
        }
    },[userLoading,isLoggedIn,navigate])
  return;
} 
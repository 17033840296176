import { Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getNotices } from "../api";
import moment from "moment";
import { Link } from "react-router-dom";

export default function NoticeLitmit() {
  const { itemLimit } = { itemLimit: 4 };
  const { isLoading, data } = useQuery([``, "notice"], getNotices);

  return (
    <TableContainer mt={5}>
      <Table variant="simple">
        <Tbody>
          {data?.map((item) => (
            //created_at = moment(item.created_at).format('YYYY-MM-DD');

            <Tr key={item.id}>
              <Td padding={5}>
                <Link to={`/support/notices/${item.id}`}>{item.subject}</Link>
              </Td>

              <Td w={10}>{moment(item.created_at).format("YYYY-MM-DD")}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

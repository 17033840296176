import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Img, Text } from "@chakra-ui/react";
import { getItem, getItems } from "../api";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  const { isLoading, data } = useQuery(["items"], getItems);
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}

export default class CreatorSlide extends Component {
  render() {
    const settings = {
      className: "center",
      infinite: true,
      centerPadding: "10px",
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 6,
      swipeToSlide: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,

      afterChange: function (index) {},
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <Link to={`/market/2`}>
              <Img
                className="round100"
                mx="auto"
                src="https://yt3.ggpht.com/5XYCW7D0dlHCKVwzeeCpxAb0TiizGPj7VyFJ9d5uIBDDanggBFFZDB17g_Bhy628-jIUcSN-=s140-c-k-c0x00ffffff-no-rj"
              />
              <Text mt={5} align={"center"}>
                디디 DIDI
              </Text>
            </Link>
          </div>
          <div>
            <Link to={`/market/3`}>
              <Img
                className="round100"
                mx="auto"
                src="https://yt3.ggpht.com/dSnEm0xfQPUyo8T5vmrYwP1f2bOjcwCIsDFDg6WuVmErtGHlavgFBAOIWAYUsvaTVkhR_jAzDA=s140-c-k-c0x00ffffff-no-rj"
              />
              <Text mt={5} align={"center"}>
                GO웅호 ungho
              </Text>
            </Link>
          </div>
          <div>
            <Link to={`/market/9`}>
              <Img
                className="round100"
                mx="auto"
                src="https://yt3.ggpht.com/cdSAa8bJ1xP6wWttzrMfce4-fu_Pjra7KG8_mW84yKgmbPxSlqvOQp78oT7ok8tLnsyjlYwtVA=s140-c-k-c0x00ffffff-no-rj"
              />
              <Text mt={5} align={"center"}>
                브레드찬 BreadChan
              </Text>
            </Link>
          </div>
          <div>
            <Link to={`/market/6`}>
              <Img
                className="round100"
                mx="auto"
                src="https://yt3.ggpht.com/HT8sgVXxpG_1pnpWWcj1sMJ3tsAz-UiAbczVlq-AJ-FkST06CoJEfTjm5J6SUGJEhvYVVVIEbvg=s140-c-k-c0x00ffffff-no-rj"
              />
              <Text mt={5} align={"center"}>
                밈고리즘
              </Text>
            </Link>
          </div>
          <div>
            <Link to={`/market/8`}>
              <Img
                className="round100"
                mx="auto"
                src="https://yt3.ggpht.com/Fv0r9SXA5UnjczfrHypvd2Fvse4DZWGNw1HbbDg_uomVCtIrMO56Ne2LEWNknbYwXE2w_95Fbg=s140-c-k-c0x00ffffff-no-rj"
              />
              <Text mt={5} align={"center"}>
                메이 May I help you
              </Text>
            </Link>
          </div>
          <div>
            <Link to={`/market/10`}>
              <Img
                className="round100"
                mx="auto"
                src="https://yt3.ggpht.com/Nu3rWsbCapx9nLWBALTK0VCff2Gm-CkkkfNUPJCqme6LxMI56Q5Ykyn-OM6X9fetrJN8AnSJ=s140-c-k-c0x00ffffff-no-rj"
              />
              <Text mt={5} align={"center"}>
                iNeedTV
              </Text>
            </Link>
          </div>
          <div>
            <Link to={`/market/11`}>
              <Img
                className="round100"
                mx="auto"
                src="https://yt3.ggpht.com/p1l3ZQnW5WLCDtgdG_S5qzICeARVEILhz8Efe2YKvQnwWcNfUXn7LP8HH70eppnlDPO987sxdA=s140-c-k-c0x00ffffff-no-rj"
              />
              <Text mt={5} align={"center"}>
                오드선 odesun
              </Text>
            </Link>
          </div>
        </Slider>
      </div>
    );
  }
}

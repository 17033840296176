import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";

export default function EpsChart({ inputdata, inputdate }) {
  const epsData = inputdata.reverse();

  inputdate = inputdate.reverse();
  //console.log(inputdate);

  const [useset, Set] = useState(0);

  var options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        name: "1F 매수시 배당",
        data: epsData,
      },
    ],
    xaxis: {
      categories: inputdate,
    },
    markers: {
      size: [4, 7],
    },
  };

  useEffect(() => {
    if (epsData) {
      var chart = new ApexCharts(document.querySelector("#chart"), options);
      chart.render();
      Set(1);
    }
  }, [epsData]);
  console.log();

  return <>{useset && <div id="chart" style={{ width: "520px" }}></div>}</>;
}

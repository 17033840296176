import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Input,
  OrderedList,
  Skeleton,
  Slide,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useNumberInput,
  useToast,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { getItem } from "../api";
import EpsChart from "../components/EpsChart";
import Item from "../components/Item";
import "./ItemDetail.css";
import $ from "jquery";
import { useEffect, useState } from "react";
import { fundOder } from "../api";
import moment from "moment";
import ScrollToTop from "../lib/ScrollToTop";
import { useForm } from "react-hook-form";

export default function ItemDetail() {
  const { itemPk } = useParams();
  const { isLoading, data } = useQuery([``, itemPk], getItem);
  const [eps, Seteps] = useState([]);
  const [epsV, Setepsv] = useState([]);
  const [inputdata, setInputData] = useState([]);
  const [inputdate, setInputDate] = useState([]);
  const [inpuObject, setInputObject] = useState([]);
  const [use, SetUse] = useState(0);
  const [sliderValue, setSliderValue] = useState(1);

  //console.log(data);
  const toast = useToast();
  const { register, watch, handleSubmit } = useForm();

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 0,
      min: 0,
      max: 9999999,
      precision: 0,
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();
  $(function () {
    $(".page-indicator ").click(function (e) {
      var href = $(this).attr("href");

      //console.log(href);

      var targetTop = $(href).offset().top;
      //console.log(targetTop);

      /*
      // 한번에 가도록 하는 방법
      $(window).scrollTop(targetTop);
      */

      $("html")
        .stop()
        .animate({ scrollTop: targetTop - 150 }, 300);

      e.preventDefault();
    });

    function Page__updateIndicatorActive() {
      var scrollTop = $(window).scrollTop();

      if (scrollTop >= 200) {
        $(".sticky").addClass("fix");
      } else if (scrollTop <= 200) {
        $(".sticky").removeClass("fix");
        $(".page-indicator.active").removeClass("active");
      }

      // 역순으로 검색해야 편하다
      $($(".page").get().reverse()).each(function (index, node) {
        var $node = $(this);

        //console.log($node);

        var offsetTop = parseInt($node.attr("data-offset-top"));
        //console.log(offsetTop);

        if (scrollTop >= offsetTop - 210) {
          // 기존 녀석에게 활성화 풀고
          $(".page-indicator.active").removeClass("active");
          // 해당하는 녀석에게 활성화 넣고

          var currentPageIndex = $node.index();
          $(".page-indicator").eq(currentPageIndex).addClass("active");

          $("html").attr("data-current-page-index", currentPageIndex);

          return false; // 더 이상 다른 페이지를 검사하지 않는다.
        }
      });
    }

    // 각 페이지의 offsetTop 속성을 업데이트
    function Page__updateOffsetTop() {
      $(".page").each(function (index, node) {
        var $page = $(node);
        var offsetTop = $page.offset().top;
        //console.log(offsetTop);

        $page.attr("data-offset-top", offsetTop);
      });

      // 계산이 바뀌었으니까, 다시 상태 업데이트
      Page__updateIndicatorActive();
    }

    function Page__init() {
      Page__updateOffsetTop();
    }

    // 초기화
    Page__init();

    // 화면이 리사이즈 할 때 마다, offsetTop을 다시계산
    $(window).resize(Page__updateOffsetTop);

    // 스크롤이 될 때 마다, 인디케이터의 상태를 갱신
    $(window).scroll(Page__updateIndicatorActive);

    // const dd = $(window).innerHeight();
    // console.log(dd);
  });

  const [Fprice, SetFprice] = useState(0);
  const creater_price = data?.price;
  const { value } = { ...input };
  const { onBlur, name, ref } = register("price");

  useEffect(() => {
    //console.log(value);
    if (data) {
      try {
        // const inpuObject = data.itemhistorydata?.map((rowData) => ({
        //   idx: rowData.id,
        //   eps: rowData.h_eps,
        //   write_date: rowData.h_publish_date,
        // }));

        const _inputData = data.itemhistorydata?.map(
          (rowData) => rowData.h_eps
        );

        let epscount = 0;
        const epsPlus = data.itemhistorydata?.map((e) => {
          return { ...e, epscount: (epscount += e.h_eps) };
        });

        //console.log(epscount);
        let epsV = epscount / data.itemhistorydata.length;

        //console.log(data.itemhistorydata.length);

        const _inputDate = data.itemhistorydata?.map((rowData) =>
          moment(rowData.h_publish_date).format("YYYYMMDD")
        );

        Setepsv(epsV);
        SetUse(1);
        setInputData(_inputData);
        setInputDate(_inputDate);
      } catch (e) {
        console.error(e.message);
      }
    }
    //console.log("mouted");
  }, [data]);

  const Count = () => {
    //console.log(value);

    SetFprice(creater_price * value);
  };

  const ffff = () => {
    //console.log(1);
  };

  const onChange = (event) => {
    // console.log(1);
    // event.preventDefault();
    // console.log(1);
    // console.log(value);
    // console.log(event.target.value);
  };

  const Order = async () => {
    const status = await fundOder(Fprice, itemPk, value);
    console.log(status);
    if (status === 200) {
      toast({
        status: "success",
        title: "펀딩하였습니다",
        position: "top-right",
      });
    }
  };

  return (
    <Box
      px={{
        base: 0,
        lg: 0,
      }}
      mx="auto"
      maxWidth={1024}
      marginTop={40}
      minHeight={1200}
    >
      <Skeleton mb={5} height={"43px"} width="50%" isLoaded={!isLoading}>
        <Heading fontSize="3xl" as="b" color={"#282828"}>
          {data?.name}
        </Heading>
      </Skeleton>

      <Box className="sticky" mb={20}>
        <div className="empty"></div>
        <div className="tabs">
          <a href="#page-1" className="page-indicator boder">
            펀딩
          </a>
          <a href="#page-2" className="page-indicator boder">
            펀딩포인트
          </a>
          <a href="#page-3" className="page-indicator boder">
            채널 정보
          </a>
          <a href="#page-4" className="page-indicator ">
            배당 정보
          </a>
        </div>
      </Box>

      {/* <Tabs isLazy colorScheme="blue">
        <TabList>
          <Tab>펀딩</Tab>
          <Tab>정보</Tab>
        </TabList>
        <TabPanels>
          <TabPanel></TabPanel>

          <TabPanel>
            <p>two!</p>
          </TabPanel>
        </TabPanels>
      </Tabs> */}

      <div className="pages">
        <Box mt={20} id="page-1" className="page-box page">
          <Skeleton isLoaded={!isLoading} h="auto" w="">
            <Image objectFit={"cover"} w="" h="100%" src={data?.y_thum} />
          </Skeleton>
          <Text mt={5}>{data?.count_per}% 달성</Text>
          <Text mt={5}>가격 : {data?.price}원 </Text>
          <HStack mt={5}>
            <Text w="50px">수량</Text>
            <Button
              onClick={() => {
                Count();
              }}
              {...inc}
            >
              +
            </Button>
            <Input type="number" onChange={onChange} maxW="20%" {...input} />
            <Button
              onClick={() => {
                Count();
              }}
              {...dec}
            >
              -
            </Button>
            <Text marginLeft={50}> 펀딩총액 {Fprice}원</Text>
          </HStack>
          <Button
            onClick={() => {
              Order();
            }}
            mt={5}
          >
            펀딩하기
          </Button>
        </Box>
        <Box id="page-2" className="page-box page" mt={5}>
          <Text Text fontSize="2xl" as="b" color={"#282828"}>
            펀딩 포인트
          </Text>
          <Text mt={10}>{data?.code}</Text>
        </Box>
        <Box id="page-3" className="page-box page" mt={10}>
          <Text Text fontSize="2xl" as="b" color={"#282828"}>
            채널 정보
          </Text>

          <Text mt={10}>{data?.y_description}</Text>
          <Text mt={5}>구독자 {data?.y_sub_cnt}</Text>
          <Text mt={5}>동영상 개수 {data?.y_video_cnt}</Text>
          <Text mt={5}>총 뷰 {data?.y_view_cnt}</Text>
          <Box marginTop={20}>
            <Text fontSize="1xl" as="b" color={"#282828"}>
              인기영상
            </Text>
            <Grid
              mt={0}
              px={{
                base: 0,
                lg: 0,
              }}
              columnGap={10}
              rowGap={8}
              templateColumns={{
                sm: "1fr",
                md: "1fr 1fr",
                lg: "repeat(1, 1fr)",
                xl: "repeat(1, 1fr)",
                "2xl": "repeat(3, 1fr)",
              }}
            >
              {use &&
                data.videos?.map((video) => (
                  <Box
                    p={1}
                    position="relative"
                    //borderWidth={1}
                    overflow={"hidden"}
                    mb={0.1}
                    rounded="lg"
                  >
                    <a href={video.file} target="_blank" rel="noreferrer">
                      <Image mt={5} src={video.thumbnail}></Image>
                    </a>
                    <Text mt={10} align={"center"}></Text>
                  </Box>
                ))}
            </Grid>
          </Box>
        </Box>

        <Box id="page-4" className="page-box page" mt={10}>
          <Text fontSize="2xl" as="b" color={"#282828"}>
            배당
          </Text>
          <Text mt={10}>{sliderValue * data?.price}원 매수하면</Text>
          <Slider
            width="40%"
            aria-label="slider-ex-1"
            defaultValue={1}
            onChange={(val) => setSliderValue(val)}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          <Text>예상 배당 수익 : {sliderValue * (epsV * 12)} 원</Text>
          <Box mt={10}>
            <Text mt={10} fontSize="2xl" ash_eps="b">
              [ 1F당 월간 구매가 ] : {Math.ceil(data?.price / 12)}{" "}
            </Text>
            <Text mt={10} fontSize="2xl" ash_eps="b">
              [ 현재 1F당 수익 평균 ] : {Math.ceil(epsV)}
            </Text>
          </Box>
          <Box id="" mt={10}>
            <Text>월간 1F당 배당 수익률</Text>
            <EpsChart inputdata={inputdata} inputdate={inputdate} />
          </Box>
        </Box>
      </div>
      <ScrollToTop />
    </Box>
  );
}

import { Children } from "react";
import { createBrowserRouter } from "react-router-dom";
import Root from "./components/Root"
import ItemDetail from "./routes/ItemDetail";
import Home from "./routes/Home"
import Market from "./routes/Market";
import NotFound from "./routes/NotFound";
import Users from "./routes/User";
import GithubConfirm from "./routes/GithubConfirm";
import KakaoConfirm from "./routes/KakaoConfirm";
import MyPage from "./routes/MyPage";
import Notices from "./routes/Notices";
import Notice from "./components/Notice";



const  router = createBrowserRouter([
  
    {
        path: "/",
        element: <Root />,
        errorElement:<NotFound/>,
        children: [
          {
            path: "",
            element: <Home />,
          },
          {
            path: "market",
            element: <Market />,
          },
          {
            path: "market/:itemPk",
            element: <ItemDetail />,
          },       
          {
            path: "social",
            children:[
              {
                path:"github",
                element:<GithubConfirm/>
              },
              {
                path:"kakao",
                element:<KakaoConfirm/>
              },              
            ]
          }, 
          {
            path:"mypage",
            element:<MyPage/>
          },  
          {     
          path: "support",
          children:[
            {
              path:"notices/",
              element:<Notices/>
            },
 
            {
              path:"notices/:noticePk",
              element:<Notice/>
            },
            {
              path:"faq",
              element:<KakaoConfirm/>
            },              
          ]
        }, 
      
        ],
      },
    ]);

export default router;
import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Img, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}

export default class CreatorSlideEx extends Component {
  render() {
    const settings = {
      className: "center",
      infinite: true,
      centerPadding: "10px",
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 6,
      swipeToSlide: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,

      afterChange: function (index) {},
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <Link to={`/market/1`}>
              <Img
                mx="auto"
                className="round100"
                src="https://yt3.ggpht.com/ytc/AMLnZu_c6cJqVlZ75RX5bEtVCIF36OkUHS_4T8NYB4VD=s140-c-k-c0x00ffffff-no-rj"
              />
              <Text mt={5} align={"center"}>
                냥이아빠
              </Text>
            </Link>
          </div>
          <div>
            <Img
              className="round100"
              mx="auto"
              src="https://yt3.ggpht.com/Xh9OOeL1pLO7qVE9_uiAimeLzp7VlOIQ6sMJN9lnBQk3WSmiNmSF3MrPtUjnOjPC08M4x2KYW5M=s140-c-k-c0x00ffffff-no-rj-mo"
            />
            <Text mt={5} align={"center"}>
              메이트리
            </Text>
          </div>
          <div>
            <Img
              className="round100"
              mx="auto"
              src="https://yt3.ggpht.com/sL5ugPfl9vvwRwhf6l5APY__BZBw8qWiwgHs-uVsMPFoD5-a4opTJIcRSyrY8aY5LEESOMWJ=s140-c-k-c0x00ffffff-no-rj"
            />
            <Text mt={5} align={"center"}>
              히밥heebab
            </Text>
          </div>
          <div>
            <Img
              className="round100"
              mx="auto"
              src="https://yt3.ggpht.com/DwjOPFUTLAasyTO_nL9UW3c2H-4roI9RbWd2-VpZCdGZyICfwpw2S0uG4jXxLX2lUlU4Uu_H4Q=s140-c-k-c0x00ffffff-no-rj"
            />
            <Text mt={5} align={"center"}>
              노빠꾸탁재훈
            </Text>
          </div>
          <div>
            <Img
              className="round100"
              mx="auto"
              src="https://yt3.ggpht.com/ihi8LdmSNfcp96-gaHBE1Av3-PLotF9rOhUxTy_pY-CeHYCAkDzE1ktxfe4gbhtYR0CIYjUPba0=s140-c-k-c0x00ffffff-no-rj"
            />
            <Text mt={5} align={"center"}>
              워크맨
            </Text>
          </div>
          <div>
            <Img
              className="round100"
              mx="auto"
              src="https://yt3.ggpht.com/JPReclXNoE3QpVozSG9ypDGtU_SJmWmvMENAII7VgsCuI71rQf5zjj5OBwRrDmFomOijxJPs=s140-c-k-c0x00ffffff-no-rj"
            />
            <Text mt={5} align={"center"}>
              하미마미 Hamimommy
            </Text>
          </div>
          <div>
            <Img
              className="round100"
              mx="auto"
              src="https://yt3.ggpht.com/z6ZjfHZ7-_xDEUX29tSO1fqfMaqNMls4RNg59b4lYpl_abDJXeNRuZA6EFIStHvBO5dEaBHDiw=s140-c-k-c0x00ffffff-no-rj"
            />
            <Text mt={5} align={"center"}>
              안녕하세미
            </Text>
          </div>
        </Slider>
      </div>
    );
  }
}

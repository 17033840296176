import { Code } from "@chakra-ui/react";
import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "axios";
import Cookie from "js-cookie";

const instance =  axios.create({
    baseURL :process.env.NODE_ENV ==="development" ? "http://127.0.0.1:8000/api/v1/":"https://backend.fand.shop/api/v1/",
    withCredentials:true,

})

export  const getItems = () =>{
   return instance.get(`items/`).then((response) => response.data)
}


export const getItem = ({queryKey}:QueryFunctionContext) => {
    const [,itemPk] = queryKey
    return   instance.get(`items/${itemPk}`).then((response)=>response.data);

}

export const getNotices = ({queryKey}:QueryFunctionContext) => {
    const [,limit] = queryKey
    return   instance.get(`notices/`).then((response)=>response.data);

}
export const getNotice = ({queryKey}:QueryFunctionContext) => {
    const [,noticePk] = queryKey
    return   instance.get(`notices/${noticePk}`).then((response)=>response.data);

}


export const getMe = () => instance.get(`users/me`).then((response) =>response.data)

export const logOut = () => instance.post(`users/log-out`,null,{
    headers:{
        "X-CSRFToken": Cookie.get("csrftoken") || "",
    },
}).then((response)=>response.data)

export const githubLogIn = (code:string)=>instance.post(`/users/github`,
    {code},
    {
        headers:{
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    }
).then((response)=>response.status)


export const kakaoLogin = (code:string)=>instance.post(`/users/kakao`,
    {code},
    {
        headers:{
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    }
).then((response)=>response.status)


export interface IUsernameLoginVariables{
    username:string;
    password:string;
}

export interface IUsernameLoginSuccess{
    ok:string;

}

export interface IUsernameLoginError{
    error:string;
    
}


export const usernameLogin = ({username,password}:IUsernameLoginVariables)=>
instance.post(`/users/log-in`,
    {username,password},
    {
        headers:{
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    }
).then((response)=>response.data)




export const fundOder =(donation:Number,item:Number,total_amout:Number) => instance.post('fundOder/'  , 
    {donation,item,total_amout},
    {
        headers:{
            "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
    }
).then((response)=>response.status)

